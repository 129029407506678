/*
- PS_CopyDiscussionPostToClipboard
-- https://stackoverflow.com/questions/34191780/javascript-copy-string-to-clipboard-as-text-html
-- top answer here works for Safari where the other one didnt 
-- i commented out disabling the style sheets since i don't think that does anything useful for Copy
*/
// import _forEach from 'lodash/forEach';
import _forEachRight from 'lodash/forEachRight';

import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import store from '@/store';

export default () => {
	return new Promise((resolve, reject) => {
		// console.log('PS_CopyDiscussionPostToClipboard');
		
		let _referenceFormatTypeId = HelperGetReferenceFormatId();

		// built the html you are going to copy
		let contentToClipBoard = '';
		
		// discution post title
		// APA7 Heading 1: <p style="text-align: center;"><strong>Heading 1</strong></p>
		// MLA9 Heading 1: <p><strong>Heading 1</strong></p>
		// Turabian9 Heading 1: <p style="text-align: center;"><strong>Heading 1</strong></p>
		switch(_referenceFormatTypeId){
			case config.enums.Format.APA7:
			case config.enums.Format.Turabian9:
				contentToClipBoard += '<p style="text-align: center;"><strong>' + store.state.paperEdit.meta.Title + '</strong></p>';
				break;
			case config.enums.Format.MLA9:
				contentToClipBoard += '<p><strong>' + store.state.paperEdit.meta.Title + '</p>';
				break;
		}

		// discution post content
		if(CKEDITOR.instances.ckPaperBody){
			// there is a ck instance use it's data
			contentToClipBoard += CKEDITOR.instances.ckPaperBody.getData();

		} else {
			if(window.$vm.$route.name === 'PaperTabExport'){
				contentToClipBoard += store.state.paperEdit.paperDataBody;
			} else {
				return reject();	// should handle this better - but i don't have a ck instnace and not on the Export tab so this is worst case
			}
		}

		// discution post reference section > title
		if(_referenceFormatTypeId === config.enums.Format.MLA9){
			// check for single or multiple references
			if(store.state.paperEdit.referencesInPaper.length === 1){
				contentToClipBoard += '<p><strong>Work Cited</strong></p>';
			} else {
				contentToClipBoard += '<p><strong>Works Cited</strong></p>';
			}
		} else {
			contentToClipBoard += '<p style="text-align: center;"><strong>References</strong></p>';
		}

		// discution post reference section > references
		store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
			contentToClipBoard += '<p>' + referenceObject.displayValue + '</p>'
		});
		
		// replace any PERRLA Headings with code that fits better on something like Blackboard
		let parsedHtml = new DOMParser().parseFromString(contentToClipBoard, 'text/html');
		
		// APA7 Heading 1: <p style="text-align: center;"><strong>Heading 1</strong></p>
		// MLA9 Heading 1: <p><strong>Heading 1</strong></p>
		// Turabian9 Heading 1: <p style="text-align: center;"><strong>Heading 1</strong></p>
		let h1Elements = parsedHtml.getElementsByTagName("h1");
		_forEachRight(h1Elements, (h1Element)=>{
			let newElement = document.createElement('p');
			if(_referenceFormatTypeId === config.enums.Format.APA7 || _referenceFormatTypeId === config.enums.Format.Turabian9){
				newElement.style['text-align'] = 'center';
			}
			newElement.innerHTML = '<strong>' + h1Element.innerText + '</strong>';
			h1Element.replaceWith(newElement);
		});//e:forEach

		// APA7 Heading 2: <p><strong>Heading 2</strong></p>
		// MLA9 Heading 2: <p><em>Heading 2</em></p>
		// Turabian9 Heading 2: <p style="text-align: center;">Heading 2</p>
		let h2Elements = parsedHtml.getElementsByTagName("h2");
		_forEachRight(h2Elements, (h2Element)=>{
			let newElement = document.createElement('p');
			if(_referenceFormatTypeId === config.enums.Format.APA7){
				newElement.innerHTML = '<strong>' + h2Element.innerText + '</strong>';
			} else if(_referenceFormatTypeId === config.enums.Format.MLA9){
				newElement.innerHTML = '<em>' + h2Element.innerText + '</em>';
			} else if(_referenceFormatTypeId === config.enums.Format.Turabian9){
				newElement.style['text-align'] = 'center';
				newElement.innerHTML = h2Element.innerText;
			}
			
			h2Element.replaceWith(newElement);
		});//e:forEach

		// APA7 Heading 3: <p><em><strong>Heading 3</strong></em></p>
		// MLA9 Heading 3: <p>Heading 3</p>
		// Turabian9 Heading 3: <p><em>Heading 3</em></p>
		let h3Elements = parsedHtml.getElementsByTagName("h3");
		_forEachRight(h3Elements, (h3Element)=>{
			let newElement = document.createElement('p');
			if(_referenceFormatTypeId === config.enums.Format.APA7){
				newElement.innerHTML = '<em><strong>' + h3Element.innerText + '</strong></em>';
			} else if(_referenceFormatTypeId === config.enums.Format.MLA9){
				newElement.innerHTML = h3Element.innerText;
			} else if(_referenceFormatTypeId === config.enums.Format.Turabian9){
				newElement.innerHTML = '<em>' + h3Element.innerText + '</em>';
			}
			h3Element.replaceWith(newElement);
		});//e:forEach

		// MLA9 Heading 4: <p><strong>Heading 4.</strong></p>
		// Turabian9 Heading 4: <p>Heading 4.</p>
		let h4Elements = parsedHtml.getElementsByTagName("h4");
		_forEachRight(h4Elements, (h4Element)=>{
			let newElement = document.createElement('p');
			if(_referenceFormatTypeId === config.enums.Format.MLA9){
				newElement.innerHTML = '<strong>' + h4Element.innerText + '</strong>';
			} else if(_referenceFormatTypeId === config.enums.Format.Turabian9){
				newElement.innerHTML = h4Element.innerText;
			}
			h4Element.replaceWith(newElement);
		});//e:forEach

		// MLA9 Heading 5: <p><em>Heading 5.</em></p>
		let h5Elements = parsedHtml.getElementsByTagName("h5");
		_forEachRight(h5Elements, (h5Element)=>{
			let newElement = document.createElement('p');
			if(_referenceFormatTypeId === config.enums.Format.MLA9){
				newElement.innerHTML = '<em>' + h5Element.innerText + '</em>';
			// } else if(_referenceFormatTypeId === config.enums.Format.Turabian9){
			// 	newElement.innerHTML = h4Element.innerText;
			}
			h5Element.replaceWith(newElement);
		});//e:forEach

		// APA7 inline headings that render in the editor as SPAN tags
		let spanElements = parsedHtml.getElementsByTagName("span");
		_forEachRight(spanElements, (spanElement)=>{
			if(spanElement.classList.contains('h4')){
				// APA7 Heading 4: <p><strong>Heading 4.</strong> Sentence</p>
				let newElement = document.createElement('strong');
				newElement.innerHTML = spanElement.innerText;
				spanElement.replaceWith(newElement);	
			} else if(spanElement.classList.contains('h5')){
				if(_referenceFormatTypeId === config.enums.Format.APA7){
					// APA7 Heading 5: <p><em><strong>Heading 5.</strong></em> Sentence</p>
					let newElement = document.createElement('em');
					newElement.innerHTML = '<strong>' + spanElement.innerText + '</strong>';
					spanElement.replaceWith(newElement);	
				} else if(_referenceFormatTypeId === config.enums.Format.Turabian9){
					// Turabian9 Heading 5: <p><strong>Heading 5.</strong> Sentence.</p>
					let newElement = document.createElement('strong');
						newElement.innerHTML = spanElement.innerText;
						spanElement.replaceWith(newElement);	
				}
			}
		});//e:forEach

	
		let bodyInnerHtml = parsedHtml.getElementsByTagName("body")[0].innerHTML

		// Create container for the HTML
		let container = document.createElement('div');
		container.innerHTML = bodyInnerHtml;

		// Hide element
		container.style.position = 'fixed';
		container.style.pointerEvents = 'none';
		container.style.opacity = 0;

		// Detect all style sheets of the page
		// var activeSheets = Array.prototype.slice.call(document.styleSheets).filter(function (sheet) {
		// 	return !sheet.disabled
		// });

		// Mount the container to the DOM to make `contentWindow` available
		document.body.appendChild(container);

		// Copy to clipboard
		window.getSelection().removeAllRanges();

		let range = document.createRange();
		range.selectNode(container);
		window.getSelection().addRange(range);

		document.execCommand('copy');

		// for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true

		// document.execCommand('copy');

		// for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;

		// Remove the container
		document.body.removeChild(container);

		window.$vm.emitter.emit('globalToasterOpen',{
			textContent: 'Copied to clipboard',
		});
		
		return resolve();

	});//e:Promise
	
}