// PS_BuildHtml
// paper edit level - converts all paper parts into complete html 

import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import PCKS_NumberFigureCaptions from '@/services/paper/ck/numberFigureCaptions'
import PCKS_NumberTableCaptions from '@/services/paper/ck/numberTableCaptions'
import router from '@/router';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		const errorSourceName = 'PS_BuildHtml';
		// console.log(errorSourceName);

		// set defaults
		const _$opts = $opts || {};
		const _updateMetaTags = _has(_$opts, 'updateMetaTags') ? _$opts.updateMetaTags : null;
		
		let returnHtml = '';
		let paperId = 0;

		// meta
		_forEach(config.metaDataKeys, (metaDataKey)=>{
			// if(metaDataKey !== 'UseNewCitationMode'){
				let isMetaChange = false;
				if(_updateMetaTags){
					_forEach(_updateMetaTags, (updatedTagValue)=>{
						if(updatedTagValue[metaDataKey]){
							isMetaChange = true;
							returnHtml += '<meta name="' + metaDataKey + '" content="' + encodeURIComponent(updatedTagValue[metaDataKey]) + '" >';
						}
					});
				}
				if(metaDataKey === 'PaperID'){
					paperId = store.state.paperEdit.meta[metaDataKey] || 0;
				}
				if(!isMetaChange){
					returnHtml += '<meta name="' + metaDataKey + '" content="' + encodeURIComponent(store.state.paperEdit.meta[metaDataKey]) + '" >';
				}

			// }
		});

		returnHtml += '\r\r';
		
		// review
		_forEach(store.state.paperEdit.review, (value, key)=>{
			returnHtml += '<review name="' + key + '" content="' + value + '" />';
		});//e:_forEach

		returnHtml += '\r\r';
				
		// authorsNote
		if(store.state.paperEdit.meta.IncludeAuthorNote){
			let authorsNoteContent = '';

			// Check for the ckEditor Instance of the Abstract
			if(CKEDITOR.instances.ckPaperAuthorNote){
				authorsNoteContent = CKEDITOR.instances.ckPaperAuthorNote.getData();
				store.commit('paperEdit/authorsNote/CONTENT', authorsNoteContent);
			} else if(store.state.paperEdit.authorsNote.content){
				authorsNoteContent = store.state.paperEdit.authorsNote.content;
			} else {
				authorsNoteContent = `
					<p>ORCID iD's</p>
					<p>Changes of Affiliation</p>
					<p>Disclosures & Acknowledgements</p>
					<p>Contact Information</p>
				`;
			}
			returnHtml += '<author-note content="' + encodeURIComponent(authorsNoteContent) + '" />';
			returnHtml += '\r\r';
		}
	
		// abstract
		if(store.state.paperEdit.meta.IncludeAbstract){
			let abstractContent = null;

			if(CKEDITOR.instances.ckPaperAbstract){
				// instance exisits, has to be on the Editor Tab
				abstractContent = CKEDITOR.instances.ckPaperAbstract.getData();
			} else {
				// instance does not exisits, prob the Organize Tab
				abstractContent = store.state.paperEdit.abstract.content;
			}

			let abstractKeywords = '';

			// Check for Abstract Keywords
			let $elmTxtPaperAbstractKeywords = document.getElementById('txtPaperAbstractKeywords');
			if($elmTxtPaperAbstractKeywords){
				abstractKeywords = $elmTxtPaperAbstractKeywords.value;
			} else if(store.state.paperEdit.abstract.keywords){
				abstractKeywords = store.state.paperEdit.abstract.keywords;
			}

			returnHtml += '<abstract content="' + encodeURIComponent(abstractContent) + '" keywords="' + encodeURIComponent(abstractKeywords) + '" />';
			returnHtml += '\r\r';
		}//e:IncludeAbstract
		
		PCKS_NumberTableCaptions({
			ckEditor: 'all'
		}).then(()=>{

			PCKS_NumberFigureCaptions({
				ckEditor: 'all'
			}).then(()=>{
		
				// append paper body
				if(CKEDITOR.instances.ckPaperBody){
					returnHtml += CKEDITOR.instances.ckPaperBody.getData();
				} else if(store.state.paperEdit.paperDataBody){
					returnHtml += store.state.paperEdit.paperDataBody;
				}

				returnHtml += '\r\r';

				// add references in paper
				store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
					// console.log(referenceObject);
					returnHtml += '<reference unique-id="' + referenceObject.referenceUniqueID.toUpperCase() + '" data="' + encodeURIComponent(JSON.stringify(referenceObject)) + '" ';
				
					// check annotations
					if(store.state.paperEdit.annotations.isInclude){
						if(CKEDITOR.instances['ck_annotation_' + referenceObject.referenceUniqueID.toUpperCase()]){
							returnHtml += ' annotation="' + encodeURIComponent(CKEDITOR.instances['ck_annotation_' + referenceObject.referenceUniqueID.toUpperCase()].getData()) + '" ';
						}
					}

					returnHtml += '></reference>';
					returnHtml += '\r\r';	
				});//e:forEach

				returnHtml += '\r\r';

				// outline
				if(!_isEmpty(store.state.paperEdit.outline.fullHtml)){
					returnHtml += '<outline data="' + window.btoa(encodeURIComponent(store.state.paperEdit.outline.fullHtml)) + '"></outline>';
					returnHtml += '\r\r';	
				}

				// check for duplicate outline tags
				// if(!_isEmpty(store.state.paperEdit.outline.duplicateContent)){
				// 	store.state.paperEdit.outline.duplicateContent.forEach((duplicateContentObject)=>{
				// 		returnHtml += '<outline-duplicated data="' + duplicateContentObject + '"></outline-duplicated>';
				// 		returnHtml += '\r\r';	
				// 	});
				// }

				// appendices
				store.state.paperEdit.appendices.inPaper.forEach((appendixData)=>{
					let appendixContent = null;

					// Check for the ckEditor Instance of this looped appendix
					if(CKEDITOR.instances['ck_appendix_' + appendixData.uid]){
						// instance exisits, has to be on the Editor Tab
						appendixContent = CKEDITOR.instances['ck_appendix_' + appendixData.uid].getData();
					} else {
						// instance does not exisits, prob the Export Tab
						appendixContent = appendixData.content;
					}
					returnHtml += '<appendix uid="' + appendixData.uid + '" title="' + appendixData.label + '" sort="' + appendixData.sort + '" content="' + encodeURIComponent(appendixContent) + '"></appendix>';
					returnHtml += '\r';
				});//e:forEach
				
				returnHtml += '\r';
				

				// citations
				let citationHtmlToCheckIn = '';
				let arrayOfCitationsInThisDocument = [];


				if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){

					try {
						// console.log('store.state.paperEdit.citations.inPaper');
						// console.log(store.state.paperEdit.citations.inPaper);
	
						// this actaully checks each citation in the vuex store, and makes sure the element is actually in the paper before it saves again (user could have 20 citations and none of them in the paper yet)
						store.state.paperEdit.citations.inPaper.forEach((citationObject)=>{
							let _thisCitation = _cloneDeep(citationObject);
							// console.log('_thisCitation');
							// console.log(_thisCitation);
	
							_forEach(CKEDITOR.instances, (ckInstance)=>{
								if(ckInstance.document){
									// i need the actual citation widget node so i can add it's position in the document
									
									// start by looking for the group uid
									let $citationNode = ckInstance.document.findOne('span[data-group-unique-id="' + _thisCitation.groupUniqueID + '"]');
	
									if($citationNode){
										// group citation
										$citationNode.removeAttribute('data-citation-unique-id');
										$citationNode.removeAttribute('data-reference-unique-id');
										
									} else {
										// no group citation - look for a single
										$citationNode = ckInstance.document.findOne('span[data-citation-unique-id="' + _thisCitation.citationData.citationUniqueID + '"]');
	
										if($citationNode){
											// single citation found
											$citationNode.removeAttribute('data-group-unique-id');
											$citationNode.setAttribute('data-reference-unique-id', _thisCitation.citationData.referenceUniqueID.toUpperCase());
										}
										
									}//e:else:$citationNode
	
									// console.log('$citationNode');
									// console.log($citationNode);
	
									if($citationNode){
										let weightForEditor = 0;
										
										// i need to weight the document position based on what ckInstance it's in
										switch(ckInstance.$ckEditorType){
											case config.enums.CkEditorType.ABSTRACT:
												weightForEditor = 1;
												break;
											case config.enums.CkEditorType.BODY:
												weightForEditor = 100;
												break;
											case config.enums.CkEditorType.APPENDIX:
												weightForEditor = 10000;
												break;
										}

										_thisCitation.documentPosition = Math.round($citationNode.getDocumentPosition().y) + (Math.round($citationNode.getDocumentPosition().x) / 1000) + weightForEditor;
										arrayOfCitationsInThisDocument.push(_thisCitation);
										return false;
									}
								}//e:if
	
							});//e:forEach
							
						});//e:forEach
						
						arrayOfCitationsInThisDocument = _sortBy(arrayOfCitationsInThisDocument, 'documentPosition');
					
						citationHtmlToCheckIn = window.btoa(encodeURIComponent(JSON.stringify(arrayOfCitationsInThisDocument)));
					} catch (error){
						console.log(error);
						
						return reject({
							enum: config.enums.Error.PAPER_SAVE,
							src: errorSourceName,
						});
					}//e:try:catch

				} else {
					// paper body citations won't have a document position since i'm saving from the outline
					citationHtmlToCheckIn = window.btoa(encodeURIComponent(JSON.stringify(store.state.paperEdit.citations.inPaper)));

				}//e:if:Tab

				returnHtml += '<citations data="' + citationHtmlToCheckIn + '"></citations>';

				// footnotes - i'm probably over looping here but it's working so don't freak out too much
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					returnHtml += '\r';
					returnHtml += '\r';
					
					let footnotesInDocument = []; // array of Footnote objects 

					if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						// paper body citations won't have a document position since i'm saving from the outline
						returnHtml += '<footnotes data="' + window.btoa(encodeURIComponent(JSON.stringify(store.state.paperEdit.footnotes.inPaper))) + '"></footnotes>';
						
					} else if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						let arrayToMakeFootnotes = [];	// an array of footnote objects (doing it this way to handle group citations)

						// loop through citations actually in this document (this was collected above for <citation>) - i need to make an array just for footnote citations
						arrayOfCitationsInThisDocument.forEach((citationObject)=>{
							let _citationObject = _cloneDeep(citationObject);

							if(_has(_citationObject, 'groupUniqueID')){
								// group id

								// check if this has already been added to the object
								let findFootnote = arrayToMakeFootnotes.find((footnoteData)=>{
									return footnoteData.groupUniqueID === _citationObject.groupUniqueID
								});
								if(!findFootnote){
									arrayToMakeFootnotes.push(_citationObject);
								}

							} else {
								// no group id - just a normal push
								_citationObject.groupUniqueID = '';
								arrayToMakeFootnotes.push(_citationObject);
							}
						});//e:forEach

						// console.log('arrayToMakeFootnotes');
						// console.log(arrayToMakeFootnotes);

						arrayToMakeFootnotes.forEach((citationObject)=>{
							// console.log('citationObject');
							// console.log(citationObject);

							let findFootnote = null;

							if(citationObject.groupUniqueID === ''){
								// single citation
								try {
									findFootnote = store.state.paperEdit.footnotes.inPaper.find((footnoteData)=>{
										return footnoteData.findById.toUpperCase() === citationObject.citationData.citationUniqueID.toUpperCase();
									});
								} catch(error) {
									return reject({
										enum: config.enums.Error.PAPER_SAVE,
										src: errorSourceName,
									});
								}

							} else {
								// merged citation - look for the group uid
								if(_has(citationObject, 'groupUniqueID')){
									try {
										findFootnote = store.state.paperEdit.footnotes.inPaper.find((footnoteData)=>{
											return footnoteData.findById.toUpperCase() === citationObject.groupUniqueID.toUpperCase();
										});
									} catch(error) {
										return reject({
											enum: config.enums.Error.PAPER_SAVE,
											src: errorSourceName,
										});
									}
								}
							}

							if(findFootnote){
								footnotesInDocument.push({
									findById: findFootnote.findById,
									content: findFootnote.content,
								});
							} else {
								if(!config.isLive){
									alert('findFootnote is null');
									console.log(citationObject);
								}								
							 }//e:if:findFootnote

						});//e:forEach
	
					}//e::else:Tab

					store.commit('paperEdit/footnotes/SET_FULL_IN_PAPER', footnotesInDocument);
				
					// can i use footnotesInDocument to build an export friendly array
					let footnoteExportArray = [];
					
					// console.log('footnotesInDocument');
					// console.log(footnotesInDocument);

					// loop through references in this paper again (yes i know again but it's ok)
					store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
						if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
							
							// loop through each citation in this reference
							referenceObject.citations.forEach((citationObject)=>{
							
								// start by looking for a single citation
								let foundCitation = footnotesInDocument.find((footnoteObject)=>{
									return footnoteObject.findById.toUpperCase() === citationObject.citationData.citationUniqueID.toUpperCase()
								});

								// if still not found then try looking for it's group id
								if(!foundCitation){
									if(_has(citationObject, 'groupUniqueID')){
										foundCitation = footnotesInDocument.find((footnoteObject)=>{
											return footnoteObject.findById.toUpperCase() === citationObject.groupUniqueID.toUpperCase()
										});
									}
								}

								// at this point if the citation has been found it's good to write the export 
								if(foundCitation){
									let preContent = '<p>';
									let postContent = '</p>';

									if(foundCitation.content !== '<p>@@@</p>'){
										// there are pre and post content we need to extract

										let splitAtCitationPlaceholderArray = foundCitation.content.split('@@@');
										
										// if theres any variation here, figure out the use case and add code to check them
										if(splitAtCitationPlaceholderArray.length === 2){
											// replace html tags in the pre content
											preContent = splitAtCitationPlaceholderArray[0]
															.replace(/<strong>/gi, '<span class="b">')
															.replace(/<\/strong>/gi, '</span>')
															.replace(/<u>/gi, '<span class="u">')
															.replace(/<\/u>/gi, '</span>')
															.replace(/<em>/gi, '<span class="i">')
															.replace(/<\/em>/gi, '</span>');
											
											// replace html tags in the post content
											postContent = splitAtCitationPlaceholderArray[1]
															.replace(/<strong>/gi, '<span class="b">')
															.replace(/<\/strong>/gi, '</span>')
															.replace(/<u>/gi, '<span class="u">')
															.replace(/<\/u>/gi, '</span>')
															.replace(/<em>/gi, '<span class="i">')
															.replace(/<\/em>/gi, '</span>');
										}
									}
									
									footnoteExportArray.push({
										citationDisplayValue: citationObject.displayValue,
										pre: preContent,
										post: postContent,
										findById: foundCitation.findById.toUpperCase()
									});
									
									

								}//e:if:foundCitation
								
								
							});//e:forEach
						}

					});//e:forEach
					
					// since group citations will list here twice, just remove any duplicated ids
					footnoteExportArray = _uniqBy(footnoteExportArray, 'findById');
					
					/*
					// using this to check what's happening with Footnoes in Discusion posts
					let exportTextToCheck = `JTVCJTdCJTIyY2l0YXRpb25EaXNwbGF5VmFsdWUlMjIlM0ElMjJTdGFubGV5JTIwRS4lMjBQb3J0ZXIlMkMlMjAlM0NlbSUzRVRoZSUyMEFwb3N0bGUlMjBQYXVsJTNBJTIwSGlzJTIwTGlmZSUyQyUyMFRob3VnaHQlMkMlMjBhbmQlMjBMZXR0ZXJzJTNDJTJGZW0lM0UlMjAoR3JhbmQlMjBSYXBpZHMlMkMlMjBNSSUzQSUyMFdpbGxpYW0lMjBCLiUyMEVlcmRtYW5zJTJDJTIwMjAxNikuJTIyJTJDJTIycHJlJTIyJTNBJTIyJTNDcCUzRSUyMiUyQyUyMnBvc3QlMjIlM0ElMjIlM0MlMkZwJTNFJTIyJTJDJTIyZmluZEJ5SWQlMjIlM0ElMjI3ODZDMDY3Ri04ODEyLTQzN0QtQjU4QS1ENjhBODU4NDhDQjQlMjIlN0QlMkMlN0IlMjJjaXRhdGlvbkRpc3BsYXlWYWx1ZSUyMiUzQSUyMlBvcnRlciUyQyUyMCUzQ2VtJTNFVGhlJTIwQXBvc3RsZSUyMFBhdWwlM0MlMkZlbSUzRSUyQyUyMDMwNC4lMjIlMkMlMjJwcmUlMjIlM0ElMjIlM0NwJTNFJTIyJTJDJTIycG9zdCUyMiUzQSUyMiUzQyUyRnAlM0UlMjIlMkMlMjJmaW5kQnlJZCUyMiUzQSUyMkIxNTg2NjFELUMzMEQtNDBBRi05OEUwLTcyNkU2RDhCNTlGOSUyMiU3RCUyQyU3QiUyMmNpdGF0aW9uRGlzcGxheVZhbHVlJTIyJTNBJTIyUG9ydGVyJTJDJTIwJTNDZW0lM0VUaGUlMjBBcG9zdGxlJTIwUGF1bCUzQyUyRmVtJTNFLiUyMiUyQyUyMnByZSUyMiUzQSUyMiUzQ3AlM0UlMjIlMkMlMjJwb3N0JTIyJTNBJTIyJTNDJTJGcCUzRSUyMiUyQyUyMmZpbmRCeUlkJTIyJTNBJTIyMUVCNURDQzktQzYxQS00MTY5LTkwM0EtODEwMDdGNEIzMjJDJTIyJTdEJTJDJTdCJTIyY2l0YXRpb25EaXNwbGF5VmFsdWUlMjIlM0ElMjJQb3J0ZXIlMkMlMjAlM0NlbSUzRVRoZSUyMEFwb3N0bGUlMjBQYXVsJTNDJTJGZW0lM0UuJTIyJTJDJTIycHJlJTIyJTNBJTIyJTNDcCUzRSUyMiUyQyUyMnBvc3QlMjIlM0ElMjIlM0MlMkZwJTNFJTIyJTJDJTIyZmluZEJ5SWQlMjIlM0ElMjJBOUZEMTIwOS03RjdGLTQ3NkYtODRGMy05QzQyMTIyOTA2MDAlMjIlN0QlNUQ`;
					let decodedTextToCheck = decodeURIComponent(window.atob(exportTextToCheck));
					let jsonPaseExportTextToCheck = JSON.parse(decodedTextToCheck);

					console.log('jsonPaseExportTextToCheck');
					console.log(jsonPaseExportTextToCheck);
					
					// jsonPaseExportTextToCheck = 
					[
						{
							"citationDisplayValue": "Stanley E. Porter, <em>The Apostle Paul: His Life, Thought, and Letters</em> (Grand Rapids, MI: William B. Eerdmans, 2016).",
							"pre": "<p>",
							"post": "</p>",
							"findById": "786C067F-8812-437D-B58A-D68A85848CB4"
						},
						{
							"citationDisplayValue": "Porter, <em>The Apostle Paul</em>, 304.",
							"pre": "<p>",
							"post": "</p>",
							"findById": "B158661D-C30D-40AF-98E0-726E6D8B59F9"
						},
						{
							"citationDisplayValue": "Porter, <em>The Apostle Paul</em>.",
							"pre": "<p>",
							"post": "</p>",
							"findById": "1EB5DCC9-C61A-4169-903A-81007F4B322C"
						},
						{
							"citationDisplayValue": "Porter, <em>The Apostle Paul</em>.",
							"pre": "<p>",
							"post": "</p>",
							"findById": "A9FD1209-7F7F-476F-84F3-9C4212290600"
						}
					]
					*/

					returnHtml += '<footnotes data="' + window.btoa(encodeURIComponent(JSON.stringify(footnotesInDocument))) + '" export="' + window.btoa(encodeURIComponent(JSON.stringify(footnoteExportArray))) + '"></footnotes>';
				}//e:if:store.state.paperEdit.config.renderCitationsAsFootnotes

				// remove non breaking spaces (but i need this for pagebreaks)
				// returnHtml = returnHtml.replace(/&nbsp;/g, ' ');

				resolve({
					paperId,
					returnHtml
				});

			});//e:PCKS_NumberFigureCaptions
		});//e:PCKS_NumberTableCaptions

	});//e:Promise
}