// OS_SaveContent
// Saves outline content in the Paper Organize Tab

import _forEach from 'lodash/forEach';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('OS_SaveContent');

		// before saving the paper document, update the Vuex store object
		if(window.$ckPaperOutline) {
			PS_BuildReferencesInPaper().then(()=>{
				store.commit('loaders/ADD_ID', 'App');

				let outlineHtml = window.$ckPaperOutline.getData();
				// console.log('outlineHtml');
				// console.log(outlineHtml);

				// experimental, remove raw p tags
				// outlineHtml = outlineHtml.replaceAll('<p>', '');
				// outlineHtml = outlineHtml.replaceAll('</p>', '');

				store.commit('paperEdit/outline/FULL_HTML', outlineHtml);

				PS_SaveDocument({
					isStealthSave: true,
					message: 'Saved Organize tab',
				}).then(()=>{
					store.commit('paperEdit/outline/SET_IS_UNSAVED', false);
					
					window.$vm.emitter.emit('globalToasterOpen',{
						textContent: 'Outline changes saved',
					});
					store.commit('loaders/REMOVE_ID', 'App');
					return resolve();
				});
				
				
			});//e:PS_BuildReferencesInPaper
		} else {
			return resolve();
		}
	});//e:Promise
}