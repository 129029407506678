// PS_ParseHiddenTags
// runs when PaperEdit.vue is ready to loadPaperData()

import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import config from '@/config';
import PAnS_AddUpdate from '@/services/paper/annotations/addUpdate';
import PApS_CleanUp from '@/services/paper/appendices/cleanUp';
import store from '@/store';
import Swal from 'sweetalert2';
import toBoolean from 'validator/lib/toBoolean';	//https://www.npmjs.com/package/validator

// let isOverrideActualPaperContent = true;	// BOOl used to test users content from paper source, this will bypyass what's actualy in the paper source

export default (content) => {
	return new Promise((resolve) => {
		// console.log('PS_ParseHiddenTags');

		let parsedHtml = new DOMParser().parseFromString(content, 'text/html');

		// <meta>
		let metaElements = parsedHtml.getElementsByTagName("meta");

		
		let hasUseNewCitationModeTag = false;	// assume the user does not have this meta tag

		_forEach(metaElements, (element)=>{
			let attrName = element.getAttribute('name');
			try{
				let attrContent = decodeURIComponent(element.getAttribute('content'));
				// console.log(attrName + ': ' + attrContent);

				// if the user has this meta tag set in the paper content then i dont have to add a new one
				if(attrName === 'UseNewCitationMode'){
					hasUseNewCitationModeTag = true;
				}
				store.commit('paperEdit/meta/SET_' + attrName, attrContent);
			} catch(error){
				console.log('decodeURIComponent content error');
				console.log(error);
			}
		});//e:_forEach

		// if the user didn't have the citation meta tag - add one with value false
		if(!hasUseNewCitationModeTag){
			store.commit('paperEdit/meta/SET_UseNewCitationMode', false);
		}

		// separate conversion from PaperType to DocumentType (if it wasn't found in the meta data)
		switch(store.state.paperEdit.meta.PaperType){
			case config.enums.PaperType.APA7_AnnotatedBibliography:
			case config.enums.PaperType.MLA9_AnnotatedBibliography:
			case config.enums.PaperType.Turabian9_AnnotatedBibliography:
				store.commit('paperEdit/meta/SET_DocumentType', config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY);
				break;
			case config.enums.PaperType.APA_DiscussionPost:
			case config.enums.PaperType.APA7_DiscussionPost:
			case config.enums.PaperType.MLA_DiscussionPost:
			case config.enums.PaperType.MLA9_DiscussionPost:
			case config.enums.PaperType.Turabian9_DiscussionPost:
				store.commit('paperEdit/meta/SET_DocumentType', config.enums.DocumentType.DISCUSSION_POST);
				break;
			case config.enums.PaperType.APA7_ReferenceList:
			case config.enums.PaperType.MLA9_ReferenceList:
			case config.enums.PaperType.Turabian9_ReferenceList:
				store.commit('paperEdit/meta/SET_DocumentType', config.enums.DocumentType.REFERENCE_LIST);
				break;
			case config.enums.PaperType.APA_ResearchPaper:
			case config.enums.PaperType.APA7_ProfessionalPaper:
			case config.enums.PaperType.APA7_StudentPaper:
			case config.enums.PaperType.MLA_ResearchPaper:
			case config.enums.PaperType.MLA9_ResearchPaper:
			case config.enums.PaperType.Turabian9_ResearchPaper:
				store.commit('paperEdit/meta/SET_DocumentType', config.enums.DocumentType.RESEARCH_PAPER);
				break;
			default:
				// throw a flag here, the PaperType is needed for the editor to load properly, so no defaults
				store.commit('loaders/REMOVE_ID', 'App');

				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					text: 'PaperType not found',
					icon: 'error',
					confirmButtonText: 'Ok',
					showCloseButton: false,
					showConfirmButton: true,
					customClass:{
						confirmButton: 'btn btn-danger me-2',
					},
				});
				
				return resolve();
		}//e:switch

		store.dispatch('paperEdit/config/setPaperProperties').then(()=>{
			// <author-note>
			if(store.state.paperEdit.meta.IncludeAuthorNote){
				let authorNoteInPaperElement = parsedHtml.getElementsByTagName("author-note");
				if(authorNoteInPaperElement[0]){
					store.commit('paperEdit/authorsNote/CONTENT', decodeURIComponent(authorNoteInPaperElement[0].getAttribute('content')));
				}
			}
		
			// <abstract>
			if(store.state.paperEdit.meta.IncludeAbstract){
				// if(isOverrideActualPaperContent){
				// 	let forceAbstractContentFromPaperSource = `
				// 	%3Cp%3EDespite%20an%20expanded%20focus%20on%20educating%20students%20in%20STEM%20disciplines%2C%20nearly%20half%20of%20U.S.%20college%20students%20who%20enroll%20in%20these%20degree%20programs%20fail%20to%20graduate%20(Hamm%20et%20al.%2C%202020%2C%20p.%20623).%20Critics%20sometimes%20blame%20pedagogically%20untrained%20faculty%2C%20(e.g.%20Kahn%2C%202022).%20This%20Dissertation%20in%20Practice%20(DiP)%20focused%20on%20STEM%20university%20faculty's%20motivation%20for%20teaching%20and%20whether%20professors%20are%20motivated%20to%20enroll%20in%20pedagogy%20courses.%20Via%20the%20lens%20of%20Self%20Determination%20Theory%20(SDT)%2C%20this%20DiP%20examined%20to%20what%20extent%20STEM%20faculty%20at%20a%20major%20research%20university%20in%20the%20upper%20Midwest%20could%20devote%20additional%20time%20to%20pedagogy%20by%20enrolling%20in%20a%20college%20teaching%20graduate%20certificate%20program.%20This%20DiP%20utilized%20a%20semi-structured%20interview%20to%20address%20the%20primary%20research%20question%20of%20to%20what%20extent%20faculty%20are%20able%20or%20unable%26nbsp%3Bto%20enroll%20in%20a%20college%20teaching%20graduate%20certificate%20program%20to%20address%20critics%20who%20advocate%20for%20pedagogically-trained%20faculty.%20Findings%20indicated%20that%20...%20This%20DiP%20concludes%20that%2C%20as%20the%20results%20showed%20...%20a%20Delphi%20study%20may%20need%20to%20be%20conducted%20to%20determine%20a%20solution%20to%20the%20untrained%20faculty%20issue%20that%20more%20effectively%20balances%20student%20outcomes%20with%20faculties'%20motivation%20for%20teaching.%3C%2Fp%3E
				// 	`;

				// 	let forceAbstractKeywordsFromPaperSource = `
				// 	%3Cp%3EDespite%20an%20expanded%20focus%20on%20educating%20students%20in%20STEM%20disciplines%2C%20nearly%20half%20of%20U.S.%20college%20students%20who%20enroll%20in%20these%20degree%20programs%20fail%20to%20graduate%20(Hamm%20et%20al.%2C%202020%2C%20p.%20623).%20Critics%20sometimes%20blame%20pedagogically%20untrained%20faculty%2C%20(e.g.%20Kahn%2C%202022).%20This%20Dissertation%20in%20Practice%20(DiP)%20focused%20on%20STEM%20university%20faculty's%20motivation%20for%20teaching%20and%20whether%20professors%20are%20motivated%20to%20enroll%20in%20pedagogy%20courses.%20Via%20the%20lens%20of%20Self%20Determination%20Theory%20(SDT)%2C%20this%20DiP%20examined%20to%20what%20extent%20STEM%20faculty%20at%20a%20major%20research%20university%20in%20the%20upper%20Midwest%20could%20devote%20additional%20time%20to%20pedagogy%20by%20enrolling%20in%20a%20college%20teaching%20graduate%20certificate%20program.%20This%20DiP%20utilized%20a%20semi-structured%20interview%20to%20address%20the%20primary%20research%20question%20of%20to%20what%20extent%20faculty%20are%20able%20or%20unable%26nbsp%3Bto%20enroll%20in%20a%20college%20teaching%20graduate%20certificate%20program%20to%20address%20critics%20who%20advocate%20for%20pedagogically-trained%20faculty.%20Findings%20indicated%20that%20...%20This%20DiP%20concludes%20that%2C%20as%20the%20results%20showed%20...%20a%20Delphi%20study%20may%20need%20to%20be%20conducted%20to%20determine%20a%20solution%20to%20the%20untrained%20faculty%20issue%20that%20more%20effectively%20balances%20student%20outcomes%20with%20faculties'%20motivation%20for%20teaching.%3C%2Fp%3E
				// 	`;
					
				// 	store.commit('paperEdit/abstract/CONTENT', decodeURIComponent(forceAbstractContentFromPaperSource));

					
				// 	console.log('get me to the greek');

				// } else {
					let abstractInPaperElement = parsedHtml.getElementsByTagName("abstract");
					if(abstractInPaperElement[0]){
						store.commit('paperEdit/abstract/CONTENT', decodeURIComponent(abstractInPaperElement[0].getAttribute('content')));
						store.commit('paperEdit/abstract/KEYWORDS', decodeURIComponent(abstractInPaperElement[0].getAttribute('keywords')));

					}

				// }
			}

			// <appendix>
			let _appendicesInPaper = [];
			let appendicesInPaperElements = parsedHtml.getElementsByTagName("appendix");

			_forEach(appendicesInPaperElements, (appendix)=>{
				let appendixContent = '';
				
				// decode the appendix content
				let appendixContentDecoded = decodeURIComponent(appendix.getAttribute('content'));
				
				// parse it's content as html
				let appendixContentHtml = new DOMParser().parseFromString(appendixContentDecoded, 'text/html');
				
				// grab all the h1s in here
				let h1sInAppendix = appendixContentHtml.getElementsByTagName("h1");
				
				// add a default title element if you need to
				if(h1sInAppendix.length > 0){
					// h1s, go deeper
					let isAppendixTitleFound = false;

					// loop through all h1s in this appendix
					_forEach(h1sInAppendix, (h1)=>{
						if(h1.classList.contains('appendix-title')){
							isAppendixTitleFound = true;
							return false; // just need to find one
						}
					});//forEach:h1sInAppendix

					if(!isAppendixTitleFound){
						// an h1 was found, but not a title so make the same default one again
						appendixContent = '<h1 class="appendix-title">Type the title of your appendix here</h1>';
					}
				} else {
					// no h1s, make a new one before the content
					appendixContent = '<h1 class="appendix-title">Type the title of your appendix here</h1>';
				}
				
				// the title is ready, append the rest of the content
				appendixContent += appendixContentDecoded;

				// push this appendix to the vuex store
				_appendicesInPaper.push({
					content: appendixContent,
					// sort: appendix.getAttribute('sort'), 	// don't carry over, recalculated every time
					// label: appendix.getAttribute('label'), 	// don't carry over, recalculated every time
					uid: appendix.getAttribute('uid'), 
				});
			});
			
			_appendicesInPaper = _orderBy(_appendicesInPaper, ['sort'], ['asc']);
			
			PApS_CleanUp({
				arrayOfAppendices: _appendicesInPaper,
			}).then((cleanedAppendicesInPaper)=>{
				store.commit('paperEdit/appendices/IN_PAPER', cleanedAppendicesInPaper);
			});

			// <research-notes>
			// 2023.02.22 - commenting this out since RNs are about to live outside of a paper
			// let researchNotesInPaperElement = parsedHtml.getElementsByTagName("research-notes");
			// if(researchNotesInPaperElement[0]){
			// 	try{
			// 		window.atob(researchNotesInPaperElement[0].getAttribute('data'));
			// 		let parsedResearhNotes = JSON.parse(window.atob(researchNotesInPaperElement[0].getAttribute('data')));
			// 		// console.log('parsedResearhNotes');
			// 		// console.log(parsedResearhNotes);
			// 		store.commit('paperEdit/researchNotes/FULL_CHECKIN', parsedResearhNotes);
			// 	} catch {
			// 		console.log("Research Notes were using the old format");
			// 		store.commit('paperEdit/researchNotes/FULL_CHECKIN', {});
			// 	}
			// } else {
			// 	// hidden tag doesnt' exisit - commit an object but loop in references first
			// 	store.commit('paperEdit/researchNotes/FULL_CHECKIN', {});
			// }
		
			// <reference>
			// do not include annotations to start with
			store.commit('paperEdit/annotations/IS_INCLUDE', false);

			let _referencesInPaper = [];
			/*
				this is weird bare with me - i need to add this to help bridge people over
				when i removed <citation> from the paper HTML, i relyed on the ReferencesInPaper Array from special rules
				now i need to go back to keeping a separate object for all the citations
				but during that change over, customers will loose the connection to citations they had, it just renders as plain text
			*/
			let _citationsInPaper = [];	

			let referenceElementsInPaper = parsedHtml.getElementsByTagName("reference");
			
			_forEach(referenceElementsInPaper, (referenceElemenent)=>{
				let referenceData = JSON.parse(decodeURIComponent(referenceElemenent.getAttribute('data')));

				// console.log('referenceData from parseHiddenTags.js');
				// console.log(referenceData.displayValue);
				// console.log(referenceData.orderByValue);
				// console.log('---')

				// let valuesObject = JSON.parse(referenceData.values);

				if(_has(referenceData, 'citations') && referenceData.citations.length > 0){
					referenceData.citations.forEach((citationObject)=>{
						_citationsInPaper.push(citationObject);
					});//e:forEach:referenceData.citations
				}

				_referencesInPaper.push({
					orderByValue: referenceData.orderByValue,
					referenceUniqueID: referenceData.referenceUniqueID.toUpperCase(),
				});

				// if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 && _has(valuesObject, 'apa7')){
				// 	if(_has(referenceData, 'citations') && referenceData.citations.length > 0){
				// 		referenceData.citations.forEach((citationObject)=>{
				// 			_citationsInPaper.push(citationObject);
				// 		});//e:forEach:referenceData.citations
				// 	}

				// 	_referencesInPaper.push({
				// 		// orderByValue: valuesObject.apa7.value,
				// 		orderByValue: referenceData.orderByValue,
				// 		referenceUniqueID: referenceData.referenceUniqueID.toUpperCase(),
				// 	});

				// } else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9 && _has(valuesObject, 'mla9')){
				// 	if(_has(referenceData, 'citations') && referenceData.citations.length > 0){
				// 		referenceData.citations.forEach((citationObject)=>{
				// 			_citationsInPaper.push(citationObject);
				// 		});//e:forEach:referenceData.citations
				// 	}

				// 	_referencesInPaper.push({
				// 		// orderByValue: valuesObject.mla9.value,
				// 		orderByValue: referenceData.orderByValue,
				// 		referenceUniqueID: referenceData.referenceUniqueID.toUpperCase(),
				// 	});

				// } else if(store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 && _has(valuesObject, 'turabian9')){
				// 	if(_has(referenceData, 'citations') && referenceData.citations.length > 0){
				// 		referenceData.citations.forEach((citationObject)=>{
				// 			_citationsInPaper.push(citationObject);
				// 		});//e:forEach:referenceData.citations
				// 	}

				// 	_referencesInPaper.push({
				// 		// orderByValue: valuesObject.turabian9.value,
				// 		orderByValue: referenceData.orderByValue,
				// 		referenceUniqueID: referenceData.referenceUniqueID.toUpperCase(),
				// 	});
				// }

				// check for annotation on this reference
				if(referenceElemenent.hasAttribute('annotation')){
					PAnS_AddUpdate({
						content: decodeURIComponent(referenceElemenent.getAttribute('annotation')),
						referenceUniqueId: referenceData.referenceUniqueID.toUpperCase(),	
					});
				}//e:if
		
			});//e:forEach:referenceElementsInPaper

			_referencesInPaper = _sortBy(_referencesInPaper, ['orderByValue']);
			
			let _referenceUniqueIdsInPaper = [];
			
			_referencesInPaper.forEach((sortedReference)=>{
				_referenceUniqueIdsInPaper.push(sortedReference.referenceUniqueID);
			});

			// this is where the referenceId gets added to referenceUniqueIdsInPaper - i'd rather this add directly to the ReferenceV2Object
			store.commit('paperEdit/SET_REFERENCE_UNIQUE_IDS_IN_PAPER', _uniq(_referenceUniqueIdsInPaper));
			
			// console.log('_citationsInPaper');
			// console.log(_citationsInPaper);

			// store.commit('paperEdit/citations/SET_IN_PAPER', _citationsInPaper);


			// <outline>
			let outlineInPaperElement = parsedHtml.getElementsByTagName("outline");
			let useOultineInPaperElement; // a user had two of these recently somehow, to fix that lets look at what's in there and get only one
			
			if(outlineInPaperElement.length === 1){
				// just one - that's what i'll use
				useOultineInPaperElement = outlineInPaperElement[0];

			} else if(outlineInPaperElement.length > 1){
				// more than one - check them all and use the longest one
				_forEach(outlineInPaperElement, (outlineElement)=>{
					// store.commit('paperEdit/outline/PUSH_TO_DUPLICATE_CONTENT', outlineElement.getAttribute('data'));

					// if there isn't a use set yet - start there
					if(!useOultineInPaperElement){
						useOultineInPaperElement = outlineElement;
					}

					// check this length vs the last one that was set as the longest
					if(outlineElement.getAttribute('data').length > useOultineInPaperElement.getAttribute('data').length){
						useOultineInPaperElement = outlineElement;
					}
				});//e:forEach
			}//e:if:else

			if(useOultineInPaperElement){
				let outlineDataRaw = window.atob(useOultineInPaperElement.getAttribute('data'))
				let outlineDataEncoded = '';
				
				let isOutlineDecoded = false;

				// try to decode the outline a new way (2023.06.12)
				try {
					outlineDataEncoded = decodeURIComponent(outlineDataRaw);
					isOutlineDecoded = true;
				} catch (error) {
					// console.log(error);
				}

				// if the outline wasn't decoded as URI then it was in the old format
				if(!isOutlineDecoded){
					outlineDataEncoded = outlineDataRaw;
				}

				store.commit('paperEdit/outline/FULL_HTML', outlineDataEncoded);
				
			} else {
				store.commit('paperEdit/outline/FULL_HTML', config.startContent.outline);
			}
			
			//<outline-duplicated>
			// let outlineDuplicatesInPaperElement = parsedHtml.getElementsByTagName("outline-duplicated");
			// if(outlineDuplicatesInPaperElement.length > 0){
			// 	// loop through each duplicated outline element
			// 	_forEach(outlineDuplicatesInPaperElement, (outlineElement)=>{
			// 		store.commit('paperEdit/outline/PUSH_TO_DUPLICATE_CONTENT', outlineElement.getAttribute('data'));
			// 	});//e:forEach
			// }

			// <citations> - 2023.09.27 - i put this back in so i can have all the citations indepenedent from the Reference In Paper (this on combines all the citations into one object, so closer to footnotes)
			let allCitationsElement = parsedHtml.getElementsByTagName("citations");
			let allCitationsObject = [];
			
			if(allCitationsElement[0]){
				try {
					allCitationsObject = JSON.parse(decodeURIComponent(window.atob(allCitationsElement[0].getAttribute('data'))));
				} catch(error){
					console.log(`error - allCitationsElement[0].getAttribute('data')`);
					console.log(error);
				}
				
				store.commit('paperEdit/citations/SET_IN_PAPER', allCitationsObject);
			} else {
				// allCitationsElement wasn't found - that's ok it's probably a customer that needs their citations converted and will fix on the next document save
				store.commit('paperEdit/citations/SET_IN_PAPER', _citationsInPaper);

			}//e:if:else
			
			// create logic to determine which reference row should be "open" - this is last so <citation> can process
			if(store.state.paperEdit.openReferenceUID === ''){
				// no open id
				
				// check if there is only one reference in this paper
				if(store.state.paperEdit.referenceUniqueIdsInPaper.length === 1){
					// check if this solitary reference has any citations (they can be active or)
					if(allCitationsObject.length > 0){
						let setOpenReferenceId = store.state.paperEdit.referenceUniqueIdsInPaper[0];
						_forEach(allCitationsObject, (citationData)=>{
							if(citationData.citationData.referenceUniqueID.toUpperCase() === setOpenReferenceId.toUpperCase()){
								store.commit('paperEdit/SET_OPEN_REFERENCE_UID', setOpenReferenceId);
								return false;	// just need to find one match, after that stop looping

							}//e:if
						});//e:forEach

					}//e:if

				}//e:if

			}//e:if


			// <footnotes> 
			if(store.state.paperEdit.config.renderCitationsAsFootnotes){
				let footnotesElement = parsedHtml.getElementsByTagName("footnotes");
				if(footnotesElement[0]){
					let footnotesContent = JSON.parse(decodeURIComponent(window.atob(footnotesElement[0].getAttribute('data'))));

					// console.log('footnotesContent');
					// console.log(footnotesContent);
					
					if(footnotesContent.length === 0 && allCitationsObject.length >= 1){
						console.log('footnotesContent empty');
						// there is a footnote element, but it's content is empty AND there are actual citations in this paper - something went wrong so lets build a fresh collection of footnotes
						// console.log('allCitationsObject');
						// console.log(allCitationsObject);
						
						// loop through all citation objects
						allCitationsObject.forEach((citationObject)=>{
							// console.log('citationObject');
							// console.log(citationObject);
							let findById = citationObject.citationData.citationUniqueID.toUpperCase();

							if(_has(citationObject, 'groupUniqueID')){
								findById = citationObject.groupUniqueID
							}

							store.dispatch('paperEdit/footnotes/addToPaper', {
								findById,
							});

						});//e:forEach

						// clean up citation in paper
						store.commit('paperEdit/footnotes/CLEAN_UNIQUE');
						
						// console.log('store.state.paperEdit.footnotes.inPaper');
						// console.log(store.state.paperEdit.footnotes.inPaper);

					} else {
						console.log('footnotesContent NOT blank - but still could be empty');

						if(footnotesContent.length > 0){
							footnotesContent.forEach((footnoteData)=>{
								// console.log('footnoteData');
								// console.log(footnoteData);
								// add this to the list of footnotes in this paper
								store.dispatch('paperEdit/footnotes/addToPaper', {
									content: footnoteData.content,
									findById: footnoteData.findById,
								});
							});//:forEach

							// clean up citation in paper
							store.commit('paperEdit/footnotes/CLEAN_UNIQUE');
							
							// console.log('store.state.paperEdit.footnotes.inPaper');
							// console.log(store.state.paperEdit.footnotes.inPaper);
						}
						
					}//e:if:footnotesContent.length

				}//e:if:footnotesElement
			}//e:if:renderCitationsAsFootnotes


			// <review> (comes here becasue i need references and citations parsed first)
			let reviewElements = parsedHtml.getElementsByTagName("review");

			_forEach(reviewElements, (element)=>{
				store.commit('paperEdit/review/SET_STATE', {
					key: element.getAttribute('name'),
					value: toBoolean(element.getAttribute('content')),
				});
			});//e:_forEach

			return resolve();
		});//e:paperEdit/config/setPaperProperties
	});//e:Promise
}